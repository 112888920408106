import './App.css';
import twsvg from "./images/tw.svg"
import tgsvg from "./images/tg.svg"
import dextsvg from "./images/dext.svg"
import tw from "./images/tw.png"
import tg from "./images/tg.png"
import dext from "./images/dext.png"
import dex from "./images/dex.png"
import addyLeft from "./images/addyNormal.png"
import about1 from "./images/about1.png"
import about2 from "./images/about2.png"
import memeBase from "./images/memeBase.png"
import normalLogo from "./images/normalLogo.png"
import normalqr from "./images/bumpQR.png"
import sniperqr from "./images/sniperQR.png"
import pumpqr from "./images/pumpQR.png"
import { useEffect, useRef, useState } from 'react';
import toc from "./normalTOC.pdf"
function App() {
  return (
    <div className="App">
      <Hero></Hero>
      <Addy></Addy>
      <Bumpbot></Bumpbot>
      <Buy></Buy>
      <Generator></Generator>
      <About></About>
      <Footer></Footer>
    </div>
  );
}

function Hero(){
  return(
    <div id='Hero'>
      <div id='Menu'>
        <div id='MenuLeft'>
          <a href="#Hero">Home</a>
          <a href="#About">About</a>
          <a href="#Buy">How to Buy</a>
          <a href="#Generator">Generator</a>
        </div>
        <div id='MenuRight'>
          <a href="https://x.com/NormalPeopleSOL">
          <img src={twsvg} alt=""/>
          </a>
          <a href="https://t.me/NormalPeopleSOL">
          <img src={tgsvg} alt="" />
          </a>
          <a href="https://www.dextools.io/app/en/token/normalpeople?t=1735665661793">
          <img src={dextsvg} alt="" />
          </a>
          <a style={{padding:0}} href="https://dexscreener.com/solana/CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump">
          <img src={dex} style={{width:"32px", height:"32px"}} alt="" />
          </a>
          <button onClick={e=> window.open("https://jup.ag/swap/SOL-CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump")}>Buy $NORMAL</button>
        </div>
      </div>
    </div>
  )
}

function Addy(){
  return(
    <div id='Addy'>
      <div id='AddyTop'>
        <h2>Contract Address</h2>
        <div id='AddyTopBottom'>
          <span style={{width:"fit-content",background: "orangered",padding:"10px", borderRadius:"10px", color:"white", fontWeight:700}}>CA:</span>
          <span>CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump</span>
          <button style={{width:"fit-content", padding:"15px", borderRadius: "10px"}} onClick={async () => {
            if("clipboard" in navigator) await navigator.clipboard.writeText("CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump").then(res => console.log("Copied.")).catch(ex => console.log("Couldn't copy."))
              else document.execCommand("copy", true, "CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump")
          }}>COPY</button>
        </div>
      </div>
      <div id='AddyBottom'>
        <div id='AddyLeft'>
          <h2>Join Forces</h2>
          <h1 style={{color:"orangered"}}>With Normal People</h1>
          <div id='AddyContainer'>
            <h1 style={{color:"white"}}>Fun Facts</h1>
            <h2>Normal People's favorite catchphrase: <br/> 2 + 2 = 4</h2>
            <h2>They are the ultimate people to <br/> live the life at its finest.</h2>
            <span>Normal People are the people that embody the logic, being reasonable and living the best out of their life.</span>
          </div>
        </div>
        <div id='AddyRight'>
          <img src={addyLeft} alt="" />
        </div>
      </div>
    </div>
  )
}

function Bumpbot(){
  return(
    <div id='Bumpbot'>
      <h1>Introducing Normal Ecosystem</h1>
      <div id='BumpbotContainer'>
      <div id='BumpbotCard'>
      <h2>Normal Bump Bot</h2>
        <span>Looking to bump your PumpFun project or generate volume on your Raydium project? Use Normal Bump Bot to get quality bumps or volume for your desired Pump.fun or Raydium project!</span>
        <span>Did you know that you could generate your referral link and earn a 10% commission if people use our services through your referral link? Therefore create your referral link now, and start earning!</span>
        <img src={normalqr} alt="" />
        <button onClick={e => window.open("https://t.me/NormalBumpBot")}>Bump Now</button>
      </div>
      <div id='BumpbotCard'>
      <h2>Normal Sniper Bot</h2>
        <span>Introducing the newest sniper bot that listens to PumpFun launches and apes them if they pass through your filtering!</span>
        <span>All you have to do is fund your wallet that will be created for you on the Normal Sniper Bot on Telegram and activate your account. If the project it aped hits your profit target, it will automatically sell it! The bot can even leave moonbags for you!</span>
        <img src={sniperqr} alt="" />
        <button onClick={e => window.open("https://t.me/NormalSniperBot")}>Snipe Now</button>
      </div>
      <div id='BumpbotCard'>
      <h2>Pump Trend</h2>
        <span>Are you looking for the next 100x project on PumpFun? Imagine a channel that updates every 15 minutes to serve you the hottest PumpFun projects!</span>
        <span>Here is one of the best PumpFun trending channel to ever exist! Introducing Pump_Trend! You can also buy trending slots for your desired project and start getting more eyes on it!</span>
        <img src={pumpqr} alt="" />
        <button onClick={e => window.open("https://t.me/Pump_Trend")}>Trend Now</button>
      </div>
      </div>
      <span style={{fontSize:"10px"}}>If you use any of above bots or any of it's services, you agree and are bound with the <a style={{color:"white", textDecoration:"underline"}} href={toc}>terms and conitions</a>.</span>
    </div>
  )
}

function Buy(){
  return(
    <div id='Buy'>
      <h1>How to Buy</h1>
      <div id='BuyContainer'>
        <div id='BuyCard'>
          <h2>Create Wallet</h2>
          <span>DOWNLOAD PHANTOM OR YOUR WALLET OF CHOICE FROM THE APP STORE OR GOOGLE PLAY FOR FREE. DESKTOP USERS: DOWNLOAD THE GOOGLE CHROME EXTENSION BY GOING TO PHANTOM.</span>
            <button onClick={e=>{window.open("https://phantom.app/")}}>GET PHANTOM</button>
        </div>
        <div id='BuyCard'>
          <h2>Get Some SOL</h2>
          <span>HAVE SOL IN YOUR WALLET TO SWITCH TO $NORMAL. IF YOU DON'T HAVE ANY SOL, YOU CAN BUY DIRECTLY ON PHANTOM, TRANSFER FROM ANOTHER WALLET, OR BUY ON ANOTHER EXCHANGE AND SEND IT TO YOUR WALLET.</span>
            <button onClick={e=>{window.open("https://phantom.app/")}}>BUY SOL</button>
        </div>
        <div id='BuyCard'>
          <h2>Go to Jupiter</h2>
          <span>CONNECT TO JUPITER. CONNECT YOUR WALLET IN CHROME, PASTE THE $CHILLGUY TOKEN ADDRESS, SELECT TRADE, AND CONFIRM. WHEN PHANTOM PROMPTS YOU FOR A WALLET SIGNATURE, SIGN.</span>
            <button onClick={e=>{window.open("https://jup.ag/swap/SOL-CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump")}}>BUY NOW</button>
        </div>
        <div id='BuyCard'>
          <h2>Swap for NORMAL</h2>
          <span>SWITCH SOL FOR $NORMAL. WE HAVE ZERO TAXES SO YOU DON'T NEED TO WORRY ABOUT BUYING WITH A SPECIFIC SLIPPAGE. IF YOU DON'T HAVE ANY SOL, YOU CAN BUY DIRECTLY ON PHANTOM.</span>
          <button onClick={e=>{window.open("https://jup.ag/swap/SOL-CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump")}}>SWAP NOW</button>          
        </div>
      </div>
    </div>
  )
}

function Generator(){
  const canvasRef = useRef(null)
  const [memeText, setMemeText] = useState("")
  const [memeText2, setMemeText2] = useState("")
  const [memeText3, setMemeText3] = useState("")
  const [memeText4, setMemeText4] = useState("")
  const [memeText5, setMemeText5] = useState("")
  const [memeTitle, setMemeTitle] = useState("")
  const [imageNo, setImageNo] = useState(0)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    const img = new Image()
    img.src = imageNo === 0 ? memeBase : normalLogo
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img,0,0)
      if(imageNo === 0){
        ctx.font = "normal normal 900 70px Arial"
        ctx.fillStyle = "lightblue"
        ctx.textAlign = "center"
        ctx.fillText(memeTitle, canvas.width/1.3, canvas.height/10)
        ctx.font = "50px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeText, canvas.width/1.3, canvas.height/4)
        ctx.font = "30px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeText2, canvas.width/1.8, canvas.height/2.5)
        ctx.font = "30px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeText3, canvas.width/1.7, canvas.height/3.2)
        ctx.font = "40px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeText4, canvas.width/1.7, canvas.height/1.15)
        ctx.font = "25px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeText5, canvas.width/1.9, canvas.height/1.4)
      }
      else if(imageNo === 1){
        ctx.font = "normal normal 900 70px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeTitle, canvas.width/2, canvas.height/10)
        ctx.font = "50px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeText, canvas.width/1.3, canvas.height/4)
        ctx.font = "30px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeText2, canvas.width/4, canvas.height/4)
        ctx.font = "30px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeText3, canvas.width/5, canvas.height/1.7)
        ctx.font = "40px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeText4, canvas.width/1.7, canvas.height/1.05)
        ctx.font = "20px Arial"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        ctx.fillText(memeText5, canvas.width/1.2, canvas.height/1.4)
      }
      

    }
    
  },[memeText, memeTitle, memeText2,memeText3,memeText4,memeText5, imageNo])
 async function downloadMeme(){
  var canvas = document.getElementById("GeneratorCanvas")
  var url = canvas.toDataURL("image/png")
  var link = document.createElement("a")
  link.download = "normalPeopleMeme.png"
  link.href = url
  link.click()
 }

  return(
    <div id='Generator'>
      <h1>Meme Generator</h1>
      <canvas ref={canvasRef} id='GeneratorCanvas'></canvas>
      <button onClick={e => setImageNo(imageNo === 0 ? 1 : 0)}>Change Image</button>
      <div id='GeneratorSection'>
        <div id='GeneratorBundle'>
          <h2>Title</h2>
          <input placeholder='Title' type="text" value={memeTitle} onChange={e => setMemeTitle(e.target.value)}/>
        </div>
        <div id='GeneratorBundle'>
          <h2>Text 1</h2>
          <input contentEditable="true" placeholder='Text1' type="text" value={memeText} onChange={e => setMemeText(e.target.value)}/>
        </div>
        <div id='GeneratorBundle'>
          <h2>Text 2</h2>
          <input placeholder='Text2' type="text" value={memeText2} onChange={e => setMemeText2(e.target.value)}/>
        </div>
        <div id='GeneratorBundle'>
          <h2>Text 3</h2>
          <input placeholder='Text3' type="text" value={memeText3} onChange={e => setMemeText3(e.target.value)}/>
        </div>
        <div id='GeneratorBundle'>
          <h2>Text 4</h2>
          <input placeholder='Text4' type="text" value={memeText4} onChange={e => setMemeText4(e.target.value)}/>
        </div>
        <div id='GeneratorBundle'>
          <h2>Text 5</h2>
          <input placeholder='Text5' type="text" value={memeText5} onChange={e => setMemeText5(e.target.value)}/>
        </div>
      </div>
      
      <button onClick={e=>downloadMeme()}>Download Meme</button>
    </div>
  )
}

function About(){
  return(
    <div id='About'>
      <div id='AboutContainer'>
        <img src={about1} alt="" />
        <div id='AboutCard'>
          <h1>About</h1>
          <h2>Normal People are the people that embody the logic, being reasonable and living the best out of their life. They do not try to over-complicate things and make life harder.</h2>
        </div>
      </div>
      <div id='AboutContainer'>
        <div id='AboutCard'>
          <h1>Mission</h1>
          <h2>Our mission is to spread the lifestyle of being normal in a woke world. Through the Normal People movement, we are helping the people to discover the power of being normal, find their inner peace and learn that being woke is bullshit. No fuss, just vibes. Even Elon Musk is in the movement.</h2>
          <button onClick={e=> window.open("https://x.com/elonmusk/status/1867414209425735711")}>Elon Musk Tweet</button>
        </div>
        <img src={about2} alt="" />
      </div>
    </div>
  )
}

function Footer(){
  return(
    <div id='Footer'>
          <a href="https://x.com/NormalPeopleSOL">
          <img src={tw} alt="" />
          </a>
          <a href="https://t.me/NormalPeopleSOL">
          <img src={tg} alt="" />
          </a>
          <a href="https://www.dextools.io/app/en/token/normalpeople?t=1735665661793">
          <img src={dext} alt="" />
          </a>
          <a href="https://dexscreener.com/solana/CyhnY44hgDSycXmpRuhMv9Ctqyo9VFFMybhDaCsgpump"><img src={dex} alt="" /></a>
    </div>
  )
}
export default App;
